export function getIsMp(title: string): boolean {
    if (
        title.toLowerCase().startsWith('member of parliament') ||
        title.toLowerCase().startsWith('mp')
    ) {
        return true;
    } else {
        return false;
    }
}
